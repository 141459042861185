<template>
  <div class="wrapper">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="基本信息" name="first">
        <add-or-edit />
      </el-tab-pane>
      <el-tab-pane label="员工名单" name="second">
        <staff-list v-if="activeName === 'second'" v-permission="'ServeOrgsDetailSecondTab'" />
      </el-tab-pane>
      <el-tab-pane label="核心服务" name="third">
        <core-serve v-if="activeName === 'third'" v-permission="'ServeOrgsDetailThirdTab'" />
      </el-tab-pane>
      <el-tab-pane label="服务记录" name="fourth">
        <serviceRecord v-if="activeName === 'fourth'" v-permission="'ServeOrgsDetailFourthTab'" :service-org-id-prop="serviceOrgId" :has-tenant="true" />
      </el-tab-pane>
      <el-tab-pane label="服务对象" name="five">
        <serve-person v-if="activeName === 'five'" v-permission="'ServeOrgsDetailFiveTab'" />
      </el-tab-pane>
      <el-tab-pane label="活动记录" name="six">
        <other-import-task v-if="activeName === 'six'" v-permission="'ServeOrgsDetailSixTab'" :initiator-user-id="serviceOrgId" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import addOrEdit from './addOrEdit.vue'

import staffList from './components/staffList.vue'
import coreServe from './components/coreServe.vue'
import serviceRecord from '../../HomeCare/ServeRecords/list.vue'

import servePerson from './components/servePerson.vue'
import OtherImportTask from '@/views/Pages/task/component/otherImportTask/index.vue'

export default {
  name: 'Detail',
  components: { OtherImportTask, staffList, coreServe, serviceRecord, servePerson, addOrEdit },
  data() {
    return {
      activeName: 'first',
      serviceOrgId: (JSON.parse(this.$route.query.item)).id
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: auto;

  .m-content {
    .mc-item {
      line-height: 30px;

      span {
        display: inline-block;
        width: 150px;
        text-align: right;
      }
    }
  }

  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
